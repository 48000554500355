<template>
    <component
        :is="tag"
        v-show="isOptionSelected"
        :id="`${option.id}__content`"
        :aria-labelledby="option.id"
        :tabindex="tabIndex"
        role="tabpanel"
    >
        <slot />
    </component>
</template>

<script lang="ts" setup>
import type { TabSwitcherOption } from '@core-types/components/CoreUITabSwitcher'

const {
    tag = 'div',
    option,
    selectedOption,
    notFocusable = false,
} = defineProps<{
    /**
     * The HTML tag that should be used for the tab content container.
     * @default 'div'
     */
    tag?: 'div' | 'span' | 'section' | 'article'
    /**
     * The option that this container belongs to.
     */
    option: TabSwitcherOption
    /**
     * The currently selected option. (used to determine whether the container should be visible or not)
     */
    selectedOption: TabSwitcherOption
    /**
     * Whether the tab content container should be focusable or not.
     * The ARIA Authoring Practices Guide recommends not to disable it unless
     * there is a focusable element inside the tab content container.
     */
    notFocusable?: boolean
}>()

const isOptionSelected = computed(() => selectedOption.id === option.id)

/**
 * Whether the container should be focusable or not.
 * Since the container tag is not focusable by default, we need to set the tabindex to 0
 * if it should be currently focusable or `undefined` if it should not be focusable.
 * (This is the default.)
 */
const tabIndex = computed(() => {
    if (notFocusable) return undefined

    return isOptionSelected.value ? 0 : undefined
})

</script>

<style lang="scss" scoped>

</style>
